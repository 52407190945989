/* eslint-disable import/prefer-default-export */
/* eslint-disable camelcase */

import httpClient from './httpClient';

const authorizeUser = (username, password) => httpClient.post('/auth/login', { username, password }, { disableSwal: true });

export {
  authorizeUser,
};

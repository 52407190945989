<template>
  <v-row class="fill-height" align="center" justify="center">
    <v-col cols="auto" class="py-0">

      <v-card class="login">
        <v-card-title>
          Swift 52 POS - Login
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="login()">

            <v-alert color="red" dark v-if="errorMsg">{{errorMsg}}</v-alert>

            <!-- Username -->
            <v-text-field
              prepend-inner-icon="mdi-account-tie"
              v-model="username"
              label="Username"
              autocomplete="off"
              :disabled="loading"
              solo
            ></v-text-field>

            <!-- Password -->
            <v-text-field
              prepend-inner-icon="mdi-lock"
              v-model="password"
              type="password"
              label="Password"
              autocomplete="off"
              :disabled="loading"
              solo
            ></v-text-field>
            <div class="text-center">
              <v-btn type="submit" dense color="primary" :loading="loading">Login</v-btn>
            </div>
          </v-form>
        </v-card-text>
      </v-card>

    </v-col>
  </v-row>
</template>

<script>
export default {
  name: 'Login',
  data() {
    return {
      username: '',
      password: '',
      loading: false,
      errorMsg: undefined,
    };
  },
  methods: {
    async login() {
      this.loading = true;
      const resp = await this.$store.dispatch('auth/login', { username: this.username, password: this.password });
      if (resp.success) {
        this.showReauthorize = false;
        this.password = undefined;
      } else {
        this.errorMsg = resp.msg;
      }
      this.loading = false;
    },
  },
  created() {
    // reset login status
    this.$store.dispatch('auth/logout');
  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .login{
    width: 300px;
    max-width:300px;
  }
</style>

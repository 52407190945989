<template>

      <v-card>
        <v-card-text v-if="loading">
          <loading :loading="loading"></loading>
        </v-card-text>
        <v-card-text v-else>
          <div class="text-center">
            <strong>Sales Targets</strong>
          </div>

          <div class="heading">
            <div>Monthly</div>
            <div>{{data.monthly_value || 0 | currency}} of {{data.monthly_sales_target || 0 | currency }}</div>
          </div>
          <v-progress-linear
              :value="monthlyPercent"
              color="#FF6738"
              height="25"
          ></v-progress-linear>

          <div class="heading" style="margin-top: 15px">
            <div>Yearly</div>
            <div>{{data.yearly_value || 0 | currency}} of {{data.yearly_sales_target || 0 | currency}}</div>
          </div>
          <v-progress-linear
              :value="yearlyPercent"
              color="#FF6738"
              height="25"
          ></v-progress-linear>
        </v-card-text>
      </v-card>

</template>

<script>

import { salesTargets } from '../api/misc.api';

export default {
  name: 'SalesTargets',

  data() {
    return {
      loading: true,
      data: {},
      options: {
        maintainAspectRatio: true,
        aspectRatio: 2.0,
        responsive: true,
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label(tooltipItem) {
              return `$${Number(tooltipItem.yLabel).toFixed(0).replace(/./g, (c, i, a) => (i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c))}`;
            },
          },
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Date',
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Amount',
            },
            ticks: {
              min: 0,
              // Include a dollar sign in the ticks
              callback(value) {
                return `$${value}`;
              },
            },
          }],
        },
        legend: {
          display: false,
        },
      },
    };
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const resp = await salesTargets(false);
        this.data = resp.data;
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    monthlyPercent() {
      if (this?.data?.monthly_sales_target) {
        return (this?.data?.monthly_value || 0) / this.data.monthly_sales_target * 100
      }
      return 0;
    },
    yearlyPercent() {
      if (this?.data?.yearly_sales_target) {
        return (this?.data?.yearly_value || 0) / this.data.yearly_sales_target * 100
      }
      return 0;
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .heading {
    display: flex;
    justify-content: space-between;
  }
</style>

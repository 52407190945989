/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

import authService from '../../auth/authService';
import router from '../../router';
import httpClient from '../../api/httpClient';

const user = JSON.parse(localStorage.getItem('user'));
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: {}, user: null };

// todo set auth token if already logged in
if (user && user.token) {
  httpClient.defaults.headers.common.Authorization = `Bearer ${user.token}`;
}

const authStore = {
  namespaced: true,
  state: initialState,
  actions: {
    async login({ commit }, { username, password, reauthorize = false }) {
      // commit('loginRequest', { username });

      try {
        const resp = await authService.login(username, password);
        commit('loginSuccess', resp);
        httpClient.defaults.headers.common.Authorization = `Bearer ${resp.token}`;
        if (!reauthorize) router.push({ name: 'home' });
        return { success: true, msg: 'success!' };
      } catch (error) {
        // commit('loginFailure', error);
        httpClient.defaults.headers.common.Authorization = undefined;
        return { success: false, msg: error.response.data.message || 'Login Failed' };
      }
    },
    logout({ commit }) {
      authService.logout();
      httpClient.defaults.headers.common.Authorization = undefined;
      if (router.currentRoute.name !== 'login') {
        router.push({ name: 'login' });
      }
      commit('logout');
    },
  },
  mutations: {
    // loginRequest(state, user) {
    //   state.status = { loggingIn: true };
    //   state.user = user;
    // },
    loginSuccess(state, user) {
      state.status = { loggedIn: true };
      state.user = user;
    },
    // loginFailure(state) {
    //   state.status = {};
    //   state.user = null;
    // },
    logout(state) {
      state.status = {};
      state.user = null;
    },
  },
};

export default authStore;

/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
/* eslint-disable no-shadow */

import Vue from 'vue';

import { v4 as uuid } from 'uuid';

Vue.use(require('vue-moment'));

const getDefaultState = () => ({
  customer: undefined,
  items: [], // id, location_id, serial_number, qty, discount,
  subtotal: 0,
  downpayment: 0,
  paymentSchedule: undefined,
  basePaymentDate: Vue.moment().format('YYYY-MM-DD'),
  payments: [],
  signature: undefined,
  hstExempt: false,
});

const state = getDefaultState();

const actions = { // api calls

};

const getters = {
  getCustomer(state) {
    console.info('getter', state);
    return state.customer;
  },
};

const mutations = { // update state
  setCustomer(state, payload) {
    console.log(state, payload);
    state.customer = payload;
  },
  addItem(state, item) {
    const existingItem = state.items.filter((i) => i.product_id === item.product_id && !item.serial_number && item.product_type !== 'SERVICE')[0];

    // prevent duplicate imei
    const existingIMEI = state.items.filter((i) => item.serial_number && i.serial_number === item.serial_number)[0];
    if (existingIMEI) {
      this.$swal('Duplicate IMEI/Serial', 'This IMEI/Serial number has already been added to this order!', 'error');
    } else if (existingItem) {
      existingItem.qty_sold = (existingItem.qty_sold || 0) + 1;
    } else {
      console.log(item);
      state.items.push({
        ...item,
        key: `${item.product_id}-${item.location_id}-${item.serial_number}-${(item.product_type === 'SERVICE' ? uuid() : '')}`,
        qty_sold: 1,
        discount: 0
      });
    }
  },
  deleteItem(state, index) {
    state.items.splice(index, 1);
  },
  setSubtotal(state, amount) {
    state.subtotal = amount;
  },
  setDownpayment(state, amount) {
    state.downpayment = amount;
  },
  setPaymentSchedule(state, value) {
    state.paymentSchedule = value;
  },
  setBasePaymentDate(state, value) {
    state.basePaymentDate = value;
  },
  generatePayments(state) {
    const payments = [];
    const paymentBalance = (state.subtotal - state.downpayment);
    console.info('paymentSchedule', state.paymentSchedule);
    const perPayment = Math.ceil((paymentBalance / state.paymentSchedule.numPayments) / 5) * 5;
    let nextDate = Vue.moment(state.basePaymentDate).add(state.paymentSchedule.numIntervals, state.paymentSchedule.interval);
    let remain = paymentBalance;

    for (let index = 1; index <= state.paymentSchedule.numPayments; index += 1) {
      let amount = perPayment;
      amount = (remain - amount) <= 0 ? remain : amount;
      remain -= amount;

      if (amount === 0) break;

      payments.push({
        payment_no: index,
        amount,
        date: Vue.moment(nextDate).format('YYYY-MM-DD'),
        valid: true,
      });
      nextDate = nextDate.add(state.paymentSchedule.numIntervals, state.paymentSchedule.interval);
    }
    state.payments = payments;
  },
  deletePayment(state, index) {
    state.payments.splice(index, 1);
  },
  updatePayments(state, payments) {
    state.payments = payments;
  },
  setSignature(state, value) {
    state.signature = value;
  },
  setHstExempt(state, value) {
    state.hstExempt = value;
  },
  resetState(state) {
    Object.assign(state, getDefaultState());
  },
};

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default module;

import Vue from 'vue';
import axios from 'axios';
// import Bugsnag from '@bugsnag/js';
// import BugsnagPluginVue from '@bugsnag/plugin-vue';
import VueAxios from 'vue-axios';
import VueSwal from 'vue-swal';
import Vue2Filters from 'vue2-filters';
import vueNumeral from 'vue-numeral-filter';
import VCurrencyField from 'v-currency-field';
import VueSignaturePad from 'vue-signature-pad';
import VueMask from 'v-mask';
import * as VueGoogleMaps from 'vue2-google-maps';

import { VTextField } from 'vuetify/lib';
import App from './App.vue';
import router from './router';
import store from './store';
import loading from './components/Loading.vue';

import './registerServiceWorker';

import vuetify from './plugins/vuetify';

// console.log('baseURL', process.env.VUE_APP_BASE_URL);
// if (process.env.VUE_APP_BASE_URL.indexOf('localhost') === -1) {
//   Bugsnag.start({
//     apiKey: '26cd1ded5611d8e5cd07549290dc727e',
//     plugins: [new BugsnagPluginVue()],
//   });
//   Bugsnag.getPlugin('vue').installVueErrorHandler(Vue);
// }

// Globally import VTextField
Vue.component('v-text-field', VTextField);
Vue.use(VCurrencyField, {
  locale: 'en',
  autoDecimalMode: false,
  defaultValue: null,
});

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);

Vue.use(VueSwal);

Vue.use(vueNumeral, { locale: 'en-gb' });

Vue.use(Vue2Filters);

Vue.use(VueSignaturePad);

Vue.use(VueMask);

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCK46DOyR6ItZgFTcmvKbxmBjVJRr_9ai0',
    // libraries: 'places',
  },
  autobindAllEvents: true,
  installComponents: true,
});

Vue.use(require('vue-cookies'));

Vue.use(require('vue-moment'));

Vue.component('loading', loading);

Vue.prototype.$eventHub = new Vue();

new Vue({
  store,
  router,
  vuetify,
  render: (h) => h(App),
}).$mount('#app');

const Reports = () => import(/* webpackChunkName: "Reports" */ '../../views/Reports/Reports.vue');
const SalesRepPerformance = () => import(/* webpackChunkName: "Reports" */ '../../views/Reports/SalesRepPerformance.vue');
const CollectedPayments = () => import(/* webpackChunkName: "Reports" */ '../../views/Reports/CollectedPayments.vue');
const SettledPayments = () => import(/* webpackChunkName: "Reports" */ '../../views/Reports/SettledPayments.vue');
const OutstandingPayments = () => import(/* webpackChunkName: "Reports" */ '../../views/Reports/OutstandingPayments.vue');
const PaymentHistory = () => import(/* webpackChunkName: "Reports" */ '../../views/Reports/PaymentHistory.vue');
const ProductSales = () => import(/* webpackChunkName: "Reports" */ '../../views/Reports/ProductSales.vue');
const AccountsReceivables = () => import(/* webpackChunkName: "Reports" */ '../../views/Reports/AccountsReceivables.vue');
const Profit = () => import(/* webpackChunkName: "Reports" */ '../../views/Reports/Profit.vue');
const InventoryValue = () => import(/* webpackChunkName: "Reports" */ '../../views/Reports/InventoryValue.vue');
const Historical = () => import(/* webpackChunkName: "Reports" */ '../../views/Reports/Historical.vue');

const routes = [
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    children: [
      {
        path: '',
        redirect: 'product-sales', // default child path
      },
      {
        path: 'sales-rep-performance',
        component: SalesRepPerformance,
        name: 'salesRepPerformance',
      },
      {
        path: 'collected-payments',
        component: CollectedPayments,
        name: 'collectedPayments',
      },
      {
        path: 'payment-history',
        component: PaymentHistory,
        name: 'paymentHistory',
      },
      {
        path: 'settled-payments',
        component: SettledPayments,
        name: 'settledPayments',
      },
      {
        path: 'outstanding-payments',
        component: OutstandingPayments,
        name: 'outstandingPayments',
      },
      {
        path: 'product-sales',
        component: ProductSales,
        name: 'productSales',
      },
      {
        path: 'accounts-receivables',
        component: AccountsReceivables,
        name: 'accountsReceivables',
      },
      {
        path: 'profit',
        component: Profit,
        name: 'profit',
      },
      {
        path: 'inventoryValue',
        component: InventoryValue,
        name: 'inventoryValue',
      },
      { 
        path: 'historical',
        component: Historical,
        name: 'historical'
      }
    ],
  },
];

export default routes;

const Orders = () => import(/* webpackChunkName: "Orders" */ '../../views/Orders/Orders.vue');
const Order = () => import(/* webpackChunkName: "Orders" */ '../../views/Orders/Order.vue');
const OrderCreate = () => import(/* webpackChunkName: "Orders" */ '../../views/Orders/OrderCreate.vue');
const OrderCreate2 = () => import(/* webpackChunkName: "Orders" */ '../../views/Orders/Create/index.vue');

const routes = [
  {
    path: '/orders',
    name: 'orders',
    component: Orders,
  },

  {
    path: '/orders/create',
    name: 'orderCreate',
    component: OrderCreate,
    query: { customerId: false, farmId: false },
  },

  {
    path: '/orders/create2',
    name: 'orderCreate2',
    component: OrderCreate2,
    query: { customerId: false, farmId: false },
  },

  {
    path: '/orders/:id',
    name: 'order',
    component: Order,
  },
];

export default routes;

/* eslint-disable camelcase */

import httpClient from './httpClient';

const END_POINT = '/customers';

const list = () => httpClient.get(END_POINT, { cache: false });
const checkNameDuplicate = (params) => httpClient.get(`${END_POINT}/check-duplicate`, { params, cache: false });

const search = (params, cache = true) => httpClient.get(`${END_POINT}/search`, { params, cache });
const scan = (params, cache = true) => httpClient.get(`${END_POINT}/scan`, { params, cache });

const get = (id) => httpClient.get(`${END_POINT}/${id}`);

const save = (id, data) => httpClient.patch(`${END_POINT}/${id}`, data);

const create = (data) => httpClient.post(END_POINT, data);

const getFiles = (id) => httpClient.get(`${END_POINT}/${id}/files`);

const getFile = (id, fileName) => httpClient.get(`${END_POINT}/${id}/file/${fileName}`);

const uploadFile = (id, data) => httpClient.post(`${END_POINT}/${id}/upload-file`, data);

const getCart = (id) => httpClient.get(`${END_POINT}/${id}/cart`);

const deleteCartItem = (cartId, cartItemId) => httpClient.delete(`/carts/${cartId}/items/${cartItemId}`)

export {
  list,
  search,
  scan,
  get,
  save,
  create,
  checkNameDuplicate,
  getFiles,
  getFile,
  uploadFile,
  getCart,
  deleteCartItem,
};

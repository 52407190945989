import farms from './farms.route';
import employees from './employees.route';
import customers from './customers.route';
import inventory from './inventory.route';
import orders from './orders.route';
import payments from './payments.route';
import reports from './reports.route';
import settings from './settings.route';

export default []
  .concat(farms)
  .concat(customers)
  .concat(inventory)
  .concat(orders)
  .concat(payments)
  .concat(reports)
  .concat(employees)
  .concat(settings);

<template>
  <v-row>
    <v-col cols="12" lg="9" md="8" order-md="1" order="2">
      <v-card>
        <v-card-text v-if="loading">
          <loading :loading="loading"></loading>
        </v-card-text>
        <v-card-text v-else>
          <div class="text-center">
            <strong>Commission Summary (7 Days)</strong>
          </div>
          <line-chart
            v-if="!loading"
            :chartdata="chartdata"
            :options="options"
            :height="null"
            :width="null"
          ></line-chart>
        </v-card-text>
      </v-card>
    </v-col>
    <v-col cols="12" lg="3" md="4" order-md="2" order="1" class="py-0">
      <v-row>
        <v-col cols="6" sm="4" md="12">
          <v-card>
            <v-card-text v-if="loading">
              <loading :loading="loading"></loading>
            </v-card-text>
            <v-card-text v-else>
              <div class="text-center">
                <strong>Commission Month to Date</strong>
              </div>
              <h1 class="text-center mt-5 mb-2">{{ commissionMtd || 0 | currency}}</h1>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="12">
          <v-card>
            <v-card-text v-if="loading">
              <loading :loading="loading"></loading>
            </v-card-text>
            <v-card-text v-else>
              <div class="text-center">
                <strong>Outstanding Payments</strong>
              </div>
              <h1 class="text-center mt-5 mb-2">{{ data.summary.outstanding_payments || 0 | number('0,0')}}</h1>
            </v-card-text>
          </v-card>
        </v-col>
        <v-col cols="6" sm="4" md="12">
          <v-card>
            <v-card-text v-if="loading">
              <loading :loading="loading"></loading>
            </v-card-text>
            <v-card-text v-else>
              <div class="text-center">
                <strong>Outstanding Commission</strong>
              </div>
              <h1 class="text-center mt-5 mb-2">{{ data.summary.outstanding_commission || 0 | currency}}</h1>
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>

import LineChart from './LineChart.vue';
import { commissionSummary } from '../../api/misc.api';

export default {
  name: 'PaymentSummaryChart',
  components: {
    LineChart,
  },
  // props: {
  //   // height: {
  //   //   type: String,
  //   //   default: '100%',
  //   // },
  // },
  data() {
    return {
      loading: true,
      data: {},
      options: {
        maintainAspectRatio: true,
        aspectRatio: 2.0,
        responsive: true,
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label(tooltipItem) {
              return `$${Number(tooltipItem.yLabel).toFixed(0).replace(/./g, (c, i, a) => (i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c))}`;
            },
          },
        },
        scales: {
          xAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Date',
            },
          }],
          yAxes: [{
            scaleLabel: {
              display: true,
              labelString: 'Amount',
            },
            ticks: {
              min: 0,
              // Include a dollar sign in the ticks
              callback(value) {
                return `$${value}`;
              },
            },
          }],
        },
        legend: {
          display: false,
        },
      },
      chartdata: {},
    };
  },
  computed: {
    commissionMtd() {
      return this.data?.summary?.commission_mtd;
    },
  },
  methods: {
    async load() {
      this.loading = true;
      try {
        const resp = await commissionSummary(false);
        this.data = resp.data;
        this.parseChartData(this.data);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    parseChartData(data) {
      console.log(data);
      const dates = data.last_7_days.map((d) => this.$moment.utc(d.date).format('MMM. Do'));
      const amount = data.last_7_days.map((d) => d.amount);

      this.chartdata = {
        labels: dates,
        datasets: [
          {
            label: 'Commission',
            borderColor: '#3d8ec6',
            data: amount,
            fill: false,
          },
        ],
      };

      console.log(this.chartdata);
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

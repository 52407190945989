/* eslint-disable camelcase */
import httpClient from './httpClient';

const END_POINT = '/productCategories';

const get = (id) => httpClient.get(`${END_POINT}/${id}`);

const list = () => httpClient.get(`${END_POINT}`, { cache: false });

const lookup = (params) => httpClient.get(`${END_POINT}/lookup`, { params });

const save = (id, data) => httpClient.patch(`${END_POINT}/${id}`, data);

const create = (data) => httpClient.post(END_POINT, data);

const remove = (id) => httpClient.delete(`${END_POINT}/${id}`);

export {
  get,
  create,
  list,
  lookup,
  save,
  remove
};

<template>
  <v-app-bar app clipped-left color="primary" dark>
    <router-link :to="{ name: 'home' }" class="unstyledLink white--text pr-3">
      <v-toolbar-title>Swift52 POS</v-toolbar-title>
    </router-link>

    <v-btn text :to="{ name: 'orderCreate' }">
      <v-icon left>mdi-plus</v-icon> Order
    </v-btn>
    <v-btn text :to="{ name: 'customer', params: { id: 'create' } }">
      <v-icon left>mdi-plus</v-icon> Customer
    </v-btn>

    <v-spacer></v-spacer>

    <v-menu
      v-model="showSearchResults"
      rounded="t-0"
      transition="scroll-y-transition"
      offset-y
      left
      nudge-bottom="13"
      min-width="300"
      max-width="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          v-model="query"
          class="mr-3"
          hide-details
          light
          dense
          solo
          prepend-inner-icon="mdi-magnify"
          placeholder="Search"
          style="max-width: 200px;"
          autocomplete="off"
        ></v-text-field>
        <span v-bind="attrs" v-on="on"></span>
      </template>
      <v-card>
        <div v-if="searching" class="text-center py-5">
          <v-progress-circular
            indeterminate
            color="primary"
            class="mb-2"
          ></v-progress-circular>
        </div>

        <v-list v-if="!searching && !results">
          <v-list-item>
            <v-list-item-content>
              <v-list-item-title>No Results</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>

        <div v-if="!searching && results && results.customers">
          <v-list dense class="pa-0">
            <v-list-item dark class="grey darken-2" style="min-height:20px">
                <v-list-item-subtitle>Customers:</v-list-item-subtitle>
            </v-list-item>
            <template v-for="(customer, index) in results.customers" >
              <v-list-item :key="customer.customer_id" :to="{ name: 'customer', params: { id: customer.customer_id }  }">
                <v-list-item-avatar class="my-0">
                  <v-icon>mdi-account</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{customer.customer_name}}</v-list-item-title>
                  <v-list-item-subtitle>{{customer.farm_name}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < results.customers.length - 1"
                :key="index + 'd'"
              ></v-divider>
            </template>
            <v-list-item v-if="!searching && results && (!results.customers || results.customers.length == 0)">
              <v-list-item-content>
                <v-list-item-title>No Customers</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

        <div v-if="!searching && results && results.farms">
          <v-list dense class="pb-0">
            <v-list-item dark class="grey darken-2" style="min-height:20px">
                <v-list-item-subtitle>Farms:</v-list-item-subtitle>
            </v-list-item>
            <template v-for="(farm, index) in results.farms" >
              <v-list-item :key="farm.farm_id" :to="{ name: 'farm', params: { id: farm.farm_id }  }">
                <v-list-item-avatar class="my-0">
                  <v-icon>mdi-barn</v-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{farm.farm_name}}</v-list-item-title>
                  <v-list-item-subtitle>{{farm.sales_rep_name}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < results.farms.length - 1"
                :key="index + 'd'"
              ></v-divider>
            </template>
            <v-list-item v-if="!searching && results && (!results.farms || results.farms.length == 0)">
              <v-list-item-content>
                <v-list-item-title>No Farms</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>

        <div v-if="!searching && results && results.farms">
          <v-list dense>
            <v-list-item dark class="grey darken-2" style="min-height:20px">
                <v-list-item-subtitle>Products:</v-list-item-subtitle>
            </v-list-item>
            <template v-for="(product, index) in results.products" >
              <v-list-item :key="product.product_id" :to="{ name: 'product', params: { id: product.product_id }  }">
                <v-list-item-avatar class="my-0">
                  <product-type-icon :product-type="product.product_type"></product-type-icon>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>{{product.name}}</v-list-item-title>
                  <v-list-item-subtitle>{{product.sku}}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-divider
                v-if="index < results.products.length - 1"
                :key="index + 'd'"
              ></v-divider>
            </template>
            <v-list-item v-if="!searching && results && (!results.products || results.products.length == 0)">
              <v-list-item-content>
                <v-list-item-title>No Products</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
        </div>
      </v-card>
    </v-menu>

    <v-menu open-on-hover bottom offset-y nudge-bottom="13" transition="scroll-y-transition" :close-delay="500">
      <template v-slot:activator="{ on, attrs }">
        <div v-bind="attrs" v-on="on">
          <v-avatar size="36" color="grey">
            <span class="white--text">{{ user.first_name.substring(0, 1).toUpperCase() + user.last_name.substring(0, 1).toUpperCase() }}</span>
          </v-avatar>
        </div>
      </template>

      <v-list>
        <v-list-item link @click="logout()">
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item>
        <!-- <v-list-item link @click="test401()">
          <v-list-item-title>Test 401</v-list-item-title>
        </v-list-item> -->
      </v-list>

    </v-menu>

  </v-app-bar>
</template>

<script>
import { search, test401 } from '../../api/misc.api';
import ProductTypeIcon from '../ProductTypeIcon.vue';

export default {
  name: 'Appbar',
  components: {
    ProductTypeIcon,
  },
  data: () => ({
    query: undefined,
    searching: false,
    showSearchResults: false,
    debounceTimeout: null,
    results: [],
  }),
  methods: {
    // Log the user out
    logout() {
      this.$store.dispatch('auth/logout');
    },
    async test401() {
      await test401();
    },
    async search(query) {
      if (!query) return;
      const resp = await search({
        q: query,
      }, false);
      this.results = resp.data;
    },
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },
  watch: {
    query(query) {
      // hide results if query is empty
      if (!query) this.showSearchResults = false;
      else this.showSearchResults = true;

      if (this.debounceTimeout) {
        clearTimeout(this.debounceTimeout);
      }

      this.searching = true;

      this.debounceTimeout = setTimeout(() => {
        this.search(query);
        this.searching = false;
      }, 800);
    },
    showSearchResults(shown) {
      if (!shown) this.query = undefined;
    },
  },
};
</script>

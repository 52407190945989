import Vue from 'vue';
import Vuetify, {
  VApp,
  VAutocomplete,
  VToolbar,
  VCard,
  VCheckbox,
  VBtn,
  VBtnToggle,
  VIcon,
  VPagination,
  VProgressCircular,
  VTextarea,
  VList,
  VAvatar,
  VListGroup,
} from 'vuetify/lib';
import {
  Ripple,
} from 'vuetify/lib/directives';

Vue.use(Vuetify, {
  components: {
    VApp,
    VAutocomplete,
    VToolbar,
    VCard,
    VCheckbox,
    VBtn,
    VBtnToggle,
    VPagination,
    VProgressCircular,
    VList,
    VListGroup,
    VTextarea,
    VAvatar,
    VIcon,
  },
  directives: {
    Ripple,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#3D8EC6',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
        warning: '#FFC107',
      },
    },
  },
});

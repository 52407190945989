const Products = () => import(/* webpackChunkName: "Inventory" */ '../../views/Products/Products.vue');
const Product = () => import(/* webpackChunkName: "Inventory" */ '../../views/Products/Product.vue');
const ProductInventory = () => import(/* webpackChunkName: "Inventory" */ '../../views/Products/ProductInventory.vue');
const ProductSerials = () => import(/* webpackChunkName: "Inventory" */ '../../views/Products/ProductSerials.vue');
const ProductAccessories = () => import(/* webpackChunkName: "Inventory" */ '../../views/Products/ProductAccessories.vue');
const BulkReceiving = () => import(/* webpackChunkName: "Inventory" */ '../../views/Inventory/BulkReceiving.vue');
const BulkTransfer = () => import(/* webpackChunkName: "Inventory" */ '../../views/Inventory/BulkTransfer.vue');
const TakeInventory = () => import(/* webpackChunkName: "Inventory" */ '../../views/Inventory/TakeInventory.vue');

const routes = [
  {
    path: '/products',
    name: 'products',
    component: Products,
  },
  {
    path: '/products/:id',
    name: 'product',
    component: Product,
    children: [
      {
        path: 'inventory',
        component: ProductInventory,
        name: 'productInventory',
      },
      {
        path: 'accessories',
        component: ProductAccessories,
        name: 'productAccessories',
      },
      {
        path: 'serials',
        component: ProductSerials,
        name: 'productSerials',
      },
    ],
  },
  {
    path: '/inventory/bulk-receiving',
    component: BulkReceiving,
    name: 'bulkReceiving',
  },
  {
    path: '/inventory/bulk-transfer',
    component: BulkTransfer,
    name: 'bulkTransfer',
  },
  {
    path: '/inventory/take-inventory',
    component: TakeInventory,
    name: 'takeInventory',
  },
];

export default routes;

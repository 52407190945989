const Farms = () => import(/* webpackChunkName: "Farms" */ '../../views/Farms/Farms.vue');
const Farm = () => import(/* webpackChunkName: "Farms" */ '../../views/Farms/Farm.vue');
const FarmCustomers = () => import(/* webpackChunkName: "Farms" */ '../../views/Farms/FarmCustomers.vue');
const FarmOrders = () => import(/* webpackChunkName: "Farms" */ '../../views/Farms/FarmOrders.vue');
const FarmPayments = () => import(/* webpackChunkName: "Farms" */ '../../views/Farms/FarmPayments.vue');
const FarmPermissions = () => import(/* webpackChunkName: "Farms" */ '../../views/Farms/FarmPermissions.vue');

const routes = [
  {
    path: '/farms',
    name: 'farms',
    component: Farms,
  },
  {
    path: '/farms/:id',
    component: Farm,
    name: 'farm',
    children: [
      {
        path: 'customers',
        component: FarmCustomers,
        name: 'farmCustomers',
      },
      {
        path: 'orders',
        component: FarmOrders,
        name: 'farmOrders',
      },
      {
        path: 'payments',
        component: FarmPayments,
        name: 'farmPayments',
      },
      {
        path: 'permissions',
        component: FarmPermissions,
        name: 'farmPermissions',
      },
    ],
  },
];

export default routes;

/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */

// import the api endpoints
import { list as listEmployees } from '../../api/employees.api';
import { list as listFarms } from '../../api/farms.api';
import { list as listProductCategories } from '../../api/productCategories.api';
import { list as listProductSuppliers } from '../../api/suppliers.api';
import { list as listInventoryLocations } from '../../api/inventoryLocations.api';
import { list as listCustomers } from '../../api/customers.api';
import { list as listProducts } from '../../api/products.api';

const state = {
  salesReps: [],
  farms: [],
  productCategories: [],
  productSuppliers: [],
  inventoryLocations: [],
  customers: [],
  products: [],
};

const actions = {
  async fetchSalesReps({ commit }) {
    try {
      const response = await listEmployees();
      commit('SET_SALES_REPS', response.data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchFarms({ commit }) {
    try {
      const response = await listFarms();
      commit('SET_FARMS', response.data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchProductCategories({ commit }) {
    try {
      const response = await listProductCategories();
      commit('SET_PRODUCT_CATEGORIES', response.data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchProductSuppliers({ commit }) {
    try {
      const response = await listProductSuppliers();
      commit('SET_PRODUCT_SUPPLIERS', response.data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchInventoryLocations({ commit }) {
    try {
      const response = await listInventoryLocations();
      commit('SET_INVENTORY_LOCATIONS', response.data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchCustomers({ commit }) {
    try {
      const response = await listCustomers();
      commit('SET_CUSTOMERS', response.data);
    } catch (err) {
      console.error(err);
    }
  },
  async fetchProducts({ commit }) {
    try {
      const response = await listProducts();
      commit('SET_PRODUCTS', response.data);
    } catch (err) {
      console.error(err);
    }
  },
};

const getters = {
  salesReps(state) {
    return state.salesReps;
  },
  farms(state) {
    return state.farms;
  },
  productCategories(state) {
    return state.productCategories;
  },
  productSuppliers(state) {
    return state.productSuppliers;
  },
  inventoryLocations(state) {
    return state.inventoryLocations;
  },
  customers(state) {
    return state.customers;
  },
  products(state) {
    return state.products;
  },
};

const mutations = {
  SET_SALES_REPS(state, data) {
    state.salesReps = data;
  },
  SET_FARMS(state, data) {
    state.farms = data;
  },
  SET_PRODUCT_CATEGORIES(state, data) {
    state.productCategories = data;
  },
  SET_PRODUCT_SUPPLIERS(state, data) {
    state.productSuppliers = data;
  },
  SET_INVENTORY_LOCATIONS(state, data) {
    state.inventoryLocations = data;
  },
  SET_CUSTOMERS(state, data) {
    state.customers = data;
  },
  SET_PRODUCTS(state, data) {
    state.products = data;
  },
};

const module = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default module;

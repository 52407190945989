import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import auth from './modules/auth.store';
import dropdowns from './modules/dropdowns.store';
import orderCreate from './modules/orderCreate.store';

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    auth,
    dropdowns,
    orderCreate,
  },
  plugins: [createPersistedState({
    paths: [
      'orderCreate',
    ],
  })],
});

export default store;

<template>
  <v-card v-if="loading" class="mb-3">
    <v-card-text class="text-center">
      <v-progress-circular
        indeterminate
        color="primary"
        class="mb-2"
      ></v-progress-circular>
      <div>
        {{ message || 'Loading...'}}
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    message: String,
  },
};
</script>

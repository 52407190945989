/* eslint-disable no-param-reassign */
import axios from 'axios';
import { cacheAdapterEnhancer } from 'axios-extensions';
import Vue from 'vue';
// import router from '../router';

// create cnacellation token
let cancelTokens = [];
const generateCancelToken = () => {
  const source = axios.CancelToken.source();
  cancelTokens.push(source);
  return source;
};

const httpClient = axios.create({
  baseURL: `${process.env.VUE_APP_BASE_URL}/api/`,
  timeout: 30000, // 30 second timeout
  headers: {
    'Content-Type': 'application/json',
    // anything you want to add to the headers
  },
  adapter: cacheAdapterEnhancer(axios.defaults.adapter),
});

// Add a request interceptor
httpClient.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    console.info('HTTP REQUEST INITIATED', config);
    const source = generateCancelToken();
    config.cancelToken = source.token;
    return config;
  },
  (error) => {
    console.info('HTTP REQUEST ERROR', error);
    // Do something with request error
    return Promise.reject(error);
  },
);

// Add a response interceptor
httpClient.interceptors.response.use(
  (response) => {
    console.info('HTTP RESPONSE', response);
    // Do something with response data
    return response;
  },
  (error) => {
    if (axios.isCancel(error)) {
      console.log('HTTP CANCELLED', error.message);
    } else if (error && error.response && error.response.status === 401) {
      // router.push({ name: 'login' });
      httpClient.cancelPending();
      Vue.prototype.$eventHub.$emit('reauthorize', error);
      // window.location = '/login';
    } else if (!error.config.disableSwal) {
      console.info('HTTP RESPONSE ERROR', error, error.response);
      Vue.$swal('Houston, We have a problem!', error.response.data.message || 'An unknown error occured', 'error').then(() => document.activeElement.blur());
    } else {
      console.info('HTTP RESPONSE ERROR', error, error.response);
    }
    return Promise.reject(error);
  },
);

httpClient.cancelPending = () => {
  cancelTokens.forEach((token) => {
    token.cancel('Route Changed');
  });
  cancelTokens = [];
};

export default httpClient;

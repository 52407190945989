<template>
  <v-row class="home" style="max-width: 1200px;">
    <v-col cols="12">
      <h2>Home</h2>
    </v-col>

    <v-col cols="12" v-if="user.user_role === 'Executive'">
      <executive-summary-orders/>
    </v-col>

    <v-col cols="12" v-if="user.user_role === 'Executive'">
      <executive-summary-collections/>
    </v-col>

    <v-col cols="12" v-if="user.user_role !== 'Executive'">
      <commissions-summary/>
    </v-col>

    <v-col cols="12">
      <sales-targets/>
    </v-col>

    <v-col cols="12">
      <payment-summary></payment-summary>
    </v-col>
  </v-row>
</template>

<script>
import executiveSummaryOrders from '../components/charts/ExecutiveSummaryOrders.vue';
import executiveSummaryCollections from '../components/charts/ExecutiveSummaryCollections.vue';
import commissionsSummary from '../components/charts/CommissionsSummary.vue';
import paymentSummary from '../components/charts/PaymentSummary.vue';
import salesTargets from '../components/SalesTargets.vue';

export default {
  name: 'Home',
  props: {
    msg: String,
  },
  components: {
    paymentSummary,
    commissionsSummary,
    executiveSummaryOrders,
    executiveSummaryCollections,
    salesTargets,
  },
  data() {
    return {

    };
  },
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },
  methods: {

  },
  mounted() {

  },
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

const Settings = () => import(/* webpackChunkName: "Settings" */ '../../views/Settings/Settings.vue');
const General = () => import(/* webpackChunkName: "Settings" */ '../../views/Settings/General.vue');
const InventoryLocations = () => import(/* webpackChunkName: "Settings" */ '../../views/Settings/InventoryLocations.vue');
const ProductCategories = () => import(/* webpackChunkName: "Settings" */ '../../views/Settings/ProductCategories/index.vue');
const ProductCategory = () => import(/* webpackChunkName: "Settings" */ '../../views/Settings/ProductCategories/[id].vue');

const routes = [
  {
    path: '/settings',
    name: 'settings',
    component: Settings,
    children: [
      {
        path: 'general',
        component: General,
        name: 'settingsGeneral',
      },
      {
        path: 'inventory-locations',
        component: InventoryLocations,
        name: 'settingsInventoryLocations',
      },
      {
        path: 'product-categories',
        component: ProductCategories,
        name: 'settingsProductCategories',
      },
      {
        path: 'product-categories/:id',
        component: ProductCategory,
        name: 'settingsProductCategory',
      },
    ],
  },
];

export default routes;

<template>
  <div>
    <v-icon v-if="productType == 'DEVICE'">mdi-devices</v-icon>
    <v-icon v-if="productType == 'ACCESSORY'">mdi-tools</v-icon>
    <v-icon v-if="productType == 'SERVICE'">mdi-currency-usd</v-icon>
  </div>
</template>

<script>
export default {
  name: 'ProductTypeIcon',
  props: {
    productType: {
      type: String,
    },
  },
};
</script>

import Vue from 'vue';
import Router from 'vue-router';
// import store from '@/store/index.js';
import authGuard from '../auth/authGuard';
import routes from './routes';
import Home from '../views/Home.vue';
import Login from '../views/Login.vue';
import httpClient from '../api/httpClient';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes: [
    {
      name: 'home',
      path: '/',
      component: Home,
    },
    {
      name: 'login',
      path: '/login',
      component: Login,
    },
  ].concat(routes),
});

router.beforeEach(authGuard);

router.beforeResolve((to, from, next) => {
  httpClient.cancelPending();
  next();
});

// handle duplicate navigation (reload page)
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => {
    if (err.name === 'NavigationDuplicated') this.go();
    else throw err;
  });
};

export default router;

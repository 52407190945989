/* eslint-disable consistent-return */

const authGuard = (to, from, next) => {
  
  document.title = `Swift 52 Mobile - ${toTitleCase(to.name)}`;
  
  const publicPages = ['/login'];
  const authRequired = !publicPages.includes(to.path);
  const loggedIn = localStorage.getItem('user');

  if (authRequired && !loggedIn) {
    return next('/login');
  }

  next();
};

function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    function (txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
    }
  );
}

export default authGuard;

/* eslint-disable camelcase */

import httpClient from './httpClient';

const END_POINT = '/payments';

const list = () => httpClient.get(END_POINT, { cache: false });

const search = (params, cache = true) => httpClient.get(`${END_POINT}/search`, { params, cache });

const get = (id) => httpClient.get(`${END_POINT}/${id}`);

const save = (id, data) => httpClient.patch(`${END_POINT}/${id}`, { ...data });

const create = (data) => httpClient.post(END_POINT, data);

const summary = (cache) => httpClient.get(`${END_POINT}/summary`, { cache });

const settle = () => httpClient.post(`${END_POINT}/settle`);

export {
  list,
  search,
  get,
  save,
  create,
  summary,
  settle,
};

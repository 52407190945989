const Customers = () => import(/* webpackChunkName: "Customers" */ '../../views/Customers/Customers.vue');
const Customer = () => import(/* webpackChunkName: "Customers" */ '../../views/Customers/Customer.vue');
const CustomerFiles = () => import(/* webpackChunkName: "Customers" */ '../../views/Customers/CustomerFiles.vue');
const CustomerCart = () => import(/* webpackChunkName: "Customers" */ '../../views/Customers/CustomerCart.vue');
const CustomerOrders = () => import(/* webpackChunkName: "Customers" */ '../../views/Customers/CustomerOrders.vue');
const CustomerPayments = () => import(/* webpackChunkName: "Customers" */ '../../views/Customers/CustomerPayments.vue');

const routes = [
  {
    path: '/customers',
    name: 'customers',
    component: Customers,
  },
  {
    path: '/customers/:id',
    name: 'customer',
    component: Customer,
    children: [
      {
        path: 'cart',
        component: CustomerCart,
        name: 'customerCart',
      },
      {
        path: 'files',
        component: CustomerFiles,
        name: 'customerFiles',
      },
      {
        path: 'orders',
        component: CustomerOrders,
        name: 'customerOrders',
      },
      {
        path: 'payments',
        component: CustomerPayments,
        name: 'customerPayments',
      },
    ],
  },
];

export default routes;

const Employees = () => import(/* webpackChunkName: "Employees" */ '../../views/Employees/Employees.vue');
const Employee = () => import(/* webpackChunkName: "Employees" */ '../../views/Employees/Employee.vue');

const routes = [
  {
    path: '/employees',
    name: 'employees',
    component: Employees,
  },
  {
    path: '/employees/:id',
    name: 'employee',
    component: Employee,
  },
];

export default routes;

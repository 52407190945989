/* eslint-disable camelcase */

import httpClient from './httpClient';

const END_POINT = '/employees';

const list = () => httpClient.get(END_POINT, { cache: false });

const search = (params, cache = true) => httpClient.get(`${END_POINT}/search`, { params, cache });

// you can pass arguments to use as request parameters/data
const get = (id) => httpClient.get(`${END_POINT}/${id}`);

const save = (id, data) => httpClient.patch(`${END_POINT}/${id}`, data);

const create = (data) => httpClient.post(`${END_POINT}`, data);

const resetPassword = (id, data) => httpClient.patch(`${END_POINT}/${id}/reset-password`, data);

export {
  list,
  search,
  get,
  save,
  create,
  resetPassword,
};

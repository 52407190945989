/* eslint-disable camelcase */
import httpClient from './httpClient';

const END_POINT = '/suppliers';

const list = () => httpClient.get(END_POINT, { cache: false });

const create = (data) => httpClient.post(END_POINT, data);

const save = (id, data) => httpClient.patch(`${END_POINT}/${id}`, data);

export {
  list,
  create,
  save,
};

/* eslint-disable camelcase */

import httpClient from './httpClient';

const END_POINT = '/products';

const list = () => httpClient.get(END_POINT, { cache: false });

const listBrands = () => httpClient.get(`${END_POINT}/brands`, { cache: false })

const search = (params, cache = true) => httpClient.get(`${END_POINT}/search`, { params, cache });

const get = (id) => httpClient.get(`${END_POINT}/${id}`);

const lookup = (params, cache = true) => httpClient.get(`${END_POINT}/lookup`, { params, cache });

const scan = (params, cache = true) => httpClient.get(`${END_POINT}/scan`, { params, cache });

const save = (id, data) => httpClient.patch(`${END_POINT}/${id}`, data);

const create = (data) => httpClient.post(END_POINT, data);

const generateUpc = () => httpClient.get(`${END_POINT}/generate-upc`, { cache: false });

const generateSku = () => httpClient.get(`${END_POINT}/generate-sku`, { cache: false });

const uploadImage = (id, data) => httpClient.post(`${END_POINT}/${id}/upload-image`, data);

export {
  list,
  listBrands,
  search,
  get,
  lookup,
  scan,
  save,
  create,
  generateSku,
  generateUpc,
  uploadImage,
};

/* eslint-disable camelcase */
import httpClient from './httpClient';

const END_POINT = '/inventoryLocations';

const create = (data) => httpClient.post(END_POINT, data);

const list = () => httpClient.get(`${END_POINT}`, { cache: false });

const save = (data) => httpClient.patch(`${END_POINT}`, data);

export {
  create,
  list,
  save,
};

const Payments = () => import(/* webpackChunkName: "Payments" */ '../../views/Payments/Payments.vue');

const routes = [
  {
    path: '/payments',
    name: 'payments',
    component: Payments,
  },
];

export default routes;

/* eslint-disable camelcase */

import httpClient from './httpClient';

// you can pass arguments to use as request parameters/data
const test401 = () => httpClient.get('/test401');

const search = (params, cache = true) => httpClient.get('/search', { params, cache });

const commissionSummary = (cache = true) => httpClient.get('/commission-summary', { cache });

const executiveSummary = (cache = true) => httpClient.get('/executive-summary', { cache });

const salesTargets = (cache = true) => httpClient.get('/sales-targets', { cache });

export {
  test401,
  search,
  commissionSummary,
  executiveSummary,
  salesTargets,
};

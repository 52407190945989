import { authorizeUser } from '../api/auth.api';

function login(username, password) {
  return authorizeUser(username, password).then((resp) => {
    if (resp.data.token) {
      localStorage.setItem('user', JSON.stringify(resp.data));
    }

    return resp.data;
  });
}

function logout() {
  // remove user from local storage to log user out
  localStorage.removeItem('user');
}

function isAuthenticated() {
  if (localStorage.getItem('user')) {
    return true;
  }
  return false;
}

export default {
  login,
  logout,
  isAuthenticated,
};

<template>
<div>
  <v-app>

    <appbar v-if="isAuthenticated"></appbar>
    <sidebar v-if="isAuthenticated"></sidebar>

    <v-main class="containScroll" :class="{'blur': showReauthorize}">
      <v-container fluid class="fill-height">
        <router-view></router-view>
      </v-container>

      <v-snackbar top center :value="donwloadingUpdate" :timeout="-1" dark app absolute transition="scroll-y-reverse-transition">
        Downloading Update...
      </v-snackbar>

      <v-snackbar top center :value="updateExists" :timeout="-1" dark app absolute transition="scroll-y-reverse-transition">
        An update is available
        <template v-slot:action>
          <v-btn text @click="refreshApp">
            Update
          </v-btn>
          <v-btn text color="pink" @click="updateExists = false">
            Later
          </v-btn>
        </template>
      </v-snackbar>

      <v-snackbar top right :value="appNotInstalled" :timeout="-1" dark app absolute transition="scroll-y-reverse-transition">
        Install Pogressive Web App?
        <template v-slot:action>
          <v-btn text @click="install">
            Install
          </v-btn>
          <v-btn text color="pink" @click="installLater()">
            Later
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>

    <v-dialog persistent  v-model="showReauthorize" max-width="300">
      <v-card>
        <v-card-title class="text-center">
          Your session has expired. Please re-login!
        </v-card-title>
        <v-card-text>
          <v-form @submit.prevent="login()" :disabled="reauthorizing">

            <v-alert color="red" dark v-if="errorMsg">{{errorMsg}}</v-alert>

            <!-- Username -->
            <v-text-field
              prepend-inner-icon="mdi-account-tie"
              v-model="username"
              label="Username"
              disabled
              readonly
              dense
              solo
            ></v-text-field>

            <!-- Password -->
            <v-text-field
              prepend-inner-icon="mdi-lock"
              v-model="password"
              type="password"
              label="Password"
              autocomplete="off"
              dense
              autofocus
              solo
            ></v-text-field>

            <v-row align="center" dense justify="center">
              <v-col cols="auto">
                <v-btn type="button" dense :disabled="reauthorizing" @click="logout()">Cancel</v-btn>
              </v-col>
              <v-col cols="auto">
                <v-btn type="submit" dense color="primary" :loading="reauthorizing">Login</v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-app>
</div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue';
import update from './plugins/update';

import Appbar from './components/layout/Appbar.vue';
import Sidebar from './components/layout/Sidebar.vue';

export default {
  name: 'App',

  components: {
    Appbar,
    Sidebar,
  },
  mixins: [
    update,
  ],
  data() {
    return {
      appNotInstalled: false,
      deferredPrompt: null,
      showReauthorize: false,
      password: undefined,
      reauthErrorTrigger: undefined,
      reauthorizing: false,
      errorMsg: undefined,
    };
  },
  computed: {
    isAuthenticated() {
      return this.$store.state.auth.status.loggedIn;
    },
    username() {
      if (this.$store && this.$store.state && this.$store.state.auth && this.$store.state.auth.user && this.$store.state.auth.user.user) {
        return this.$store.state.auth.user.user.user_name || null;
      }
      return null;
    },
  },
  created() {
    window.addEventListener('beforeinstallprompt', (e) => {
      e.preventDefault();
      // Stash the event so it can be triggered later.
      this.deferredPrompt = e;
      // only show if logged in
      if (this.$cookies.get('hide-install-pwa-prompt') !== 'true') {
        this.appNotInstalled = this.isAuthenticated; // true;
      }
    });
    window.addEventListener('appinstalled', () => {
      this.deferredPrompt = null;
      this.appNotInstalled = false;
    });
    // handle barcode
    window.addEventListener('keypress', this.handleBarcode);
    // handle auth expiry
    this.$eventHub.$on('reauthorize', this.reauthorize);
  },
  methods: {
    async dismiss() {
      this.deferredPrompt = null;
      this.appNotInstalled = false;
    },
    async install() {
      this.deferredPrompt.prompt();
      this.appNotInstalled = false;
    },
    handleBarcode($event) {
      this.$eventHub.$emit('barcode-keydown', $event);
    },
    reauthorize(error) {
      this.reauthErrorTrigger = error;
      this.showReauthorize = true;
    },
    async login() {
      this.errorMsg = undefined;
      this.reauthorizing = true;
      const resp = await this.$store.dispatch('auth/login', { username: this.username, password: this.password, reauthorize: true });
      if (resp.success) {
        // reload the page if it was a get request
        if (this.reauthErrorTrigger && this.reauthErrorTrigger.response && this.reauthErrorTrigger.config && this.reauthErrorTrigger.config.method === 'get') {
          this.$router.go();
        } else {
          this.reauthErrorTrigger = undefined;
          this.showReauthorize = false;
          this.password = undefined;
          this.reauthorizing = false;
        }
      } else {
        this.errorMsg = resp.msg;
        this.reauthorizing = false;
      }
    },
    logout() {
      window.location = '/login';
    },
    installLater() {
      this.$cookies.set('hide-install-pwa-prompt', 'true', '7d');
      this.appNotInstalled = false;
    },
  },
};
</script>

<style lang="scss">
  html {
    font-family: "Roboto", sans-serif;
  }

  .container.fill-height{
    align-items: start;
  }

  .containScroll {
    max-width: 100%;
    overflow-y:auto;
    overflow-x:hidden;
  }

  //remove link underline
  .unstyledLink
  {
    text-decoration:none;
  }

  //fix text-right on inputs
  .v-input.text-right .v-text-field__slot input {
    text-align: right;
  }

  .centered-input  input {
    text-align: center !important;
  }

  //remove arrows from number input
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  //remove arrows from number input
  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

  //fix snackbars to top
  .v-snack--absolute {
    .v-snack__wrapper {
      position: fixed;
    }
  }

  .blur {
    -webkit-filter: blur(4px); /* Chrome, Safari, Opera */
    filter: blur(4px);
  }

</style>

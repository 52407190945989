<template>
  <v-row>
    <v-col cols="12">
      <v-card>
        <v-card-text v-if="loading">
          <loading :loading="loading"></loading>
        </v-card-text>
        <v-card-text v-else>
          <div class="text-center">
            <strong>Executive Summary - Orders (10 Days)</strong>
          </div>
          <line-chart
            v-if="!loading"
            :chartdata="chartdata"
            :options="options"
            :height="null"
            :width="null"
          ></line-chart>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>

import LineChart from './LineChart.vue';
import { executiveSummary } from '../../api/misc.api';

export default {
  name: 'ExecutiveSummaryChart',
  components: {
    LineChart,
  },
  // props: {
  //   // height: {
  //   //   type: String,
  //   //   default: '100%',
  //   // },
  // },
  data() {
    return {
      loading: true,
      data: {},
      options: {
        maintainAspectRatio: true,
        aspectRatio: 2.0,
        responsive: true,
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label(tooltipItem, d) {
              const dataset = d.datasets[tooltipItem.datasetIndex];

              console.log(tooltipItem, d)
              if (dataset.yAxisID === 'num-orders') {
                return `${Number(tooltipItem.yLabel).toFixed(0).replace(/./g, (c, i, a) => (i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c))}`;
              }

              return `$${Number(tooltipItem.yLabel).toFixed(0).replace(/./g, (c, i, a) => (i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c))}`;
            },
          },
        },
        scales: {
          xAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'Date',
            },
          }],
          yAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'Order Subtotal',
            },
            ticks: {
              min: 0,
              // Include a dollar sign in the ticks
              callback(value) {
                return `$${value}`;
              },
            },
          },
          {
            id: 'num-orders',
            position: 'right',
            scaleLabel: {
              display: true,
              labelString: '# Orders',
            },
            ticks: {
              min: 0,
              // Include a dollar sign in the ticks
              callback(value) {
                return `${value}`;
              },
            },
          }],
        },
        legend: {
          position: 'top',
          align: 'center',
          labels: {
            filter: function(item, chart) {
              const dataset = chart.datasets[item.datasetIndex];

              console.log(item, chart)
              // Logic to remove a particular legend item goes here
              return dataset.type === 'bar'
            }
          }
        },
      },

      chartdata: {},
    };
  },
  computed: {
    commissionMtd() {
      return this.data?.summary?.commission_mtd;
    },
  },
  methods: {
    LightenDarkenColor(color, percent) {
      let R = parseInt(color.substring(1 ,3), 16);
      let G = parseInt(color.substring(3, 5), 16);
      let B = parseInt(color.substring(5, 7), 16);

      R = parseInt(R * (100 + percent) / 100);
      G = parseInt(G * (100 + percent) / 100);
      B = parseInt(B * (100 + percent) / 100);

      R = (R<255)?R:255;
      G = (G<255)?G:255;
      B = (B<255)?B:255;

      let RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
      let GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
      let BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

      return "#"+RR+GG+BB;
    },
    async load() {
      this.loading = true;
      try {
        const resp = await executiveSummary(false);
        this.data = resp.data;
        this.parseChartData(this.data);
      } catch (err) {
        console.error(err);
      } finally {
        this.loading = false;
      }
    },
    parseChartData(data) {
      console.log(data);

      const salesReps = data.map((d) => d.sales_rep_name).filter((value, index, self) => self.indexOf(value) === index);
      const colors = ['#3d8ec6', '#FF6E40', '#1ab808'];

      console.log('salesRepos', salesReps);

      const orderSubtotal = salesReps.map((s) => {
        const setData = this.data.filter((d) => d.sales_rep_name === s).map((d) => d.order_subtotal);

        return {
          type: 'bar',
          label: s,
          data: setData,
          order: 2,
          backgroundColor: colors[salesReps.indexOf(s)],
          parsing: {
            xAxisKey: 'sales_rep_name',
            yAxisKey: 'order_subtotal',
          },
        };
      });

      const numOrders = salesReps.map((s) => {
        const setData = this.data.filter((d) => d.sales_rep_name === s).map((d) => d.num_orders);

        return {
          type: 'line',
          label: s,
          data: setData,
          order: 1,
          backgroundColor: this.LightenDarkenColor(colors[salesReps.indexOf(s)],35),
          borderColor: this.LightenDarkenColor(colors[salesReps.indexOf(s)],35),
          fill: false,
          parsing: {
            xAxisKey: 'sales_rep_name',
            yAxisKey: 'order_subtotal',
          },
          yAxisID: 'num-orders',
        };
      });

      const datasets = [...orderSubtotal, ...numOrders];

      console.log('datasets', datasets);

      this.chartdata = {
        labels: this.data.map((d) => this.$moment.utc(d.date).format('MMM. Do')).filter((value, index, self) => self.indexOf(value) === index),
        datasets,
        //     [
        //   {
        //     label: 'Order Subtotal',
        //     borderColor: '#3d8ec6',
        //     data: this.data,
        //     fill: false,
        //     parsing: {
        //       yAxisKey: 'order_subtotal',
        //     },
        //   },
        // ],
      };

      console.log(this.chartdata);
    },
  },
  mounted() {
    this.load();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

<template>
  <v-row>
    <v-col cols="12" lg="9" md="8" order-md="1" order="2">
      <v-card>
        <v-card-text v-if="loadingPaymentSummary">
          <loading :loading="loadingPaymentSummary"></loading>
        </v-card-text>
        <v-card-text v-else>
          <div class="text-center">
            <strong>Payment Collection Summary (10 Days)</strong>
          </div>
          <bar-chart
            v-if="!loadingPaymentSummary"
            :chartdata="chartdata"
            :options="options"
            :height="null"
            :width="null"
          ></bar-chart>
        </v-card-text>

        <div ref="receiptHtml" style="display: none;">
          <div style="max-width: 600px; font-size: 170%; font-family: sans-serif;" v-if="settlementReceipt">
            <center>
              <span style="font-size: 35px">Swift 52 - Payment Settlement</span><br>
              <strong>Sales Rep:</strong> {{settlementReceipt[0].sales_rep_name}}<br/>
              <strong>Timestamp:</strong> {{settlementReceipt[0].date_settled}}<br/>

              <br>
              <div v-for="(group, key) in groupedSettlementReceipt" :key="key">
                <strong>{{key}}</strong>
                <table style="width: 100%; font-size: 100%;">
                  <tr>
                    <th class="text-left" style="border-top: 1px solid #000">Cust</th>
                    <th class="text-left" style="border-top: 1px solid #000">Order</th>
                    <th class="text-left" style="border-top: 1px solid #000">Time</th>
                    <th class="text-right" style="border-top: 1px solid #000">Total</th>
                  </tr>
                  <tr v-for="(payment, index) in group" :key="index">
                    <td class="text-left">{{payment.customer_id}}</td>
                    <td class="text-left">{{payment.order_id}}</td>
                    <td class="text-left">{{payment.date_collected}}</td>
                    <td class="text-right">{{payment.total | currency}}</td>
                  </tr>
                  <tr>
                    <th class="text-left" colspan="3" style="border-top: 1px solid #000; border-bottom: 1px solid #000;">Total</th>
                    <th class="text-right" style="border-top: 1px solid #000; border-bottom: 1px solid #000;">{{getTotal(key) | currency}}</th>
                  </tr>
                </table>
                <br/>
              </div>
              <table style="width: 100%; font-size: 35px">
                <tr>
                  <th class="text-left">Grand Total</th>
                  <th class="text-right">{{grandTotal() | currency}}</th>
                </tr>
              </table>
            </center>
            <br>
          </div>
        </div>

      </v-card>
    </v-col>
    <v-col cols="6" lg="3" md="4" order-md="2" order="1">
      <v-card>
        <v-card-text v-if="loadingPaymentSummary">
          <loading :loading="loadingPaymentSummary"></loading>
        </v-card-text>
        <v-card-text v-else>
          <div class="text-center">
            <strong>Unsettled Payments</strong>
          </div>
          <v-list v-if="paymentSummary && paymentSummary.current">
            <v-list-item>
              <v-list-item-title>
                Cash
              </v-list-item-title>
              <v-list-item-title class="text-right">
                {{paymentSummary.current[0].cash_amount || 0 | currency}}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                Credit/Debit
              </v-list-item-title>
              <v-list-item-title class="text-right">
                {{paymentSummary.current[0].credit_amount || 0 | currency}}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                E-Transfer
              </v-list-item-title>
              <v-list-item-title class="text-right">
                {{paymentSummary.current[0].etransfer_amount || 0 | currency}}
              </v-list-item-title>
            </v-list-item>
            <v-list-item>
              <v-list-item-title>
                <strong>Total</strong>
              </v-list-item-title>
              <v-list-item-title class="text-right">
                <strong>{{currentTotal | currency}}</strong>
              </v-list-item-title>
            </v-list-item>
          </v-list>
          <v-btn block color="primary" @click="confirmSettlePayments()">Settle Now</v-btn>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>

</template>

<script>

import BarChart from './BarChart.vue';
import { summary, settle } from '../../api/payments.api';

export default {
  name: 'PaymentSummaryChart',
  components: {
    BarChart,
  },
  // props: {
  //   // height: {
  //   //   type: String,
  //   //   default: '100%',
  //   // },
  // },
  data() {
    return {
      loadingPaymentSummary: false,
      paymentSummary: undefined,
      settling: false,
      options: {
        maintainAspectRatio: true,
        aspectRatio: 2.0,
        responsive: true,
        tooltips: {
          mode: 'index',
          intersect: false,
          callbacks: {
            label(tooltipItem, data) {
              return `${data.datasets[tooltipItem.datasetIndex].label}: $${Number(tooltipItem.yLabel).toFixed(0).replace(/./g, (c, i, a) => (i > 0 && c !== '.' && (a.length - i) % 3 === 0 ? `,${c}` : c))}`;
            },
          },
        },
        scales: {
          xAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'Date',
            },
          }],
          yAxes: [{
            stacked: true,
            scaleLabel: {
              display: true,
              labelString: 'Amount',
            },
            ticks: {
              // min: 0,
              // Include a dollar sign in the ticks
              callback(value) {
                return `$${value}`;
              },
            },
          }],
        },
        legend: {
          position: 'top',
          align: 'center',
        },
      },
      chartdata: {},
      settlementReceipt: undefined,
    };
  },
  // computed: {
  //   styles() {
  //     return {
  //       height: this.height,
  //       position: 'relative',
  //     };
  //   },
  // },
  computed: {
    currentTotal() {
      if (!this.paymentSummary || !this.paymentSummary.current) return 0;
      return this.paymentSummary.current.reduce((a, b) => a + b.credit_amount + b.cash_amount + b.etransfer_amount || 0, 0);
    },
    groupedSettlementReceipt() {
      if (!this.settlementReceipt) return null;
      return this.settlementReceipt.reduce((r, a) => {
        // eslint-disable-next-line no-param-reassign
        r[a.payment_method] = [...r[a.payment_method] || [], a];
        return r;
      }, {});
    },
  },
  methods: {
    async getPaymentSummary() {
      this.loadingPaymentSummary = true;
      try {
        const resp = await summary(false);
        this.paymentSummary = resp.data;
        this.parseChartData(this.paymentSummary);
      } catch (err) {
        console.error(err);
      } finally {
        this.loadingPaymentSummary = false;
      }
    },
    parseChartData(data) {
      console.log(data);
      const dates = data.history.map((d) => this.$moment.utc(d.date).format('MMM. Do'));
      const cash = data.history.map((d) => d.cash_amount);
      const credit = data.history.map((d) => d.credit_amount);
      const etransfer = data.history.map((d) => d.etransfer_amount);
      const cashUnsettled = data.current.map((d) => d.cash_amount);
      const creditUnsettled = data.current.map((d) => d.credit_amount);
      const etransferUnsettled = data.current.map((d) => d.etransfer_amount);

      this.chartdata = {
        labels: dates,
        datasets: [
          {
            label: 'Cash',
            stack: 'Cash',
            backgroundColor: '#3d8ec6',
            data: cash,
          },
          {
            label: 'Cash (Unsettled)',
            stack: 'Cash',
            backgroundColor: '#C4DDED',
            data: cashUnsettled,
          },
          {
            label: 'Credit/Debit',
            stack: 'Credit',
            backgroundColor: '#FF6E40',
            data: credit,
          },
          {
            label: 'Credit/Debit (Unsettled)',
            stack: 'Credit',
            backgroundColor: '#FFD3C5',
            data: creditUnsettled,
          },
          {
            label: 'E-Transfer',
            stack: 'Etransfer',
            backgroundColor: '#1ab808',
            data: etransfer,
          },
          {
            label: 'E-Transfer (Unsettled)',
            stack: 'Etransfer',
            backgroundColor: '#a2f19c',
            data: etransferUnsettled,
          },
        ],
      };

      console.log(this.chartdata);
    },
    confirmSettlePayments() {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure you want to settle all your unsettled payments?',
        icon: 'warning',
        dangerMode: true,
        buttons: {
          cancel: {
            text: 'Cancel',
            value: false,
            visible: true,
            closeModal: true,
          },
          confirm: {
            text: 'Settle Payments',
            value: true,
            visible: true,
            closeModal: false,
          },
        },
      }).then((confirm) => {
        if (confirm) {
          this.settlePayments();
        }
      });
    },
    async settlePayments() {
      this.settling = true;
      try {
        const resp = await settle();
        this.paymentSummary = resp.data.paymentSummary;
        this.settlementReceipt = resp.data.settledPayments;
        this.parseChartData(this.paymentSummary.history);
        this.printSettlement();
        this.$swal('Success!', 'Successfully settled payments', 'success');
      } catch (err) {
        console.error(err);
      } finally {
        this.settling = false;
      }
    },
    printSettlement() {
      setTimeout(() => {
        const html = this.$refs.receiptHtml.innerHTML;
        window.location.replace(`starpassprnt://v1/print/nopreview?back=${encodeURIComponent(window.location.href)}&html=${encodeURIComponent(html)}`);
      }, 500);
    },
    getTotal(paymentMethod) {
      if (!this.settlementReceipt) return 0;
      return this.settlementReceipt.filter((p) => p.payment_method === paymentMethod).reduce((a, b) => a + b.total, 0);
    },
    grandTotal() {
      if (!this.settlementReceipt) return 0;
      return this.settlementReceipt.reduce((a, b) => a + b.total, 0);
    },
  },
  mounted() {
    this.getPaymentSummary();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>

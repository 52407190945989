<template>
    <!-- Sidebar -->
  <v-navigation-drawer app clipped permanent stateless :mini-variant="mini">

    <!-- Nav Links -->
    <v-list class="py-3">
      <!--Farms -->
      <v-list-item link :to="{ name: 'farms' }">
        <v-list-item-icon>
          <v-icon>mdi-barn</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Farms</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Customers -->
      <v-list-item link :to="{ name: 'customers' }">
        <v-list-item-icon>
          <v-icon>mdi-account-group</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Customers</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Orders -->
      <v-list-item link :to="{ name: 'orders' }">
        <v-list-item-icon>
          <v-icon>mdi-cart</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Orders</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Inventory -->
      <v-list-item link :to="{ name: 'products' }">
        <v-list-item-icon>
          <v-icon>mdi-devices</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Inventory</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Payments -->
      <v-list-item link :to="{ name: 'payments' }">
        <v-list-item-icon>
          <v-icon>mdi-currency-usd</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Payments</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Reports -->
      <v-list-item link :to="{ name: 'reports' }" v-if="user.user_role == 'Executive' || user.user_role == 'Reports' ">
        <v-list-item-icon>
          <v-icon>mdi-chart-bar</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Reports</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

      <!-- Employees -->
      <v-list-item link :to="{ name: 'employees' }" v-if="user.user_role == 'Executive'">
        <v-list-item-icon>
          <v-icon>mdi-account-tie</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Employees</v-list-item-title>
        </v-list-item-content>
      </v-list-item>

    </v-list>

    <!-- toffle mini nav -->
    <template v-slot:append>
      <v-list-item link :to="{ name: 'settingsGeneral' }">
        <v-list-item-icon>
          <v-icon>mdi-cog</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>Settings</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
      <v-list>
        <v-list-item link @click.stop="mini = !mini">
          <v-list-item-icon>
            <v-icon v-if="mini">mdi-arrow-expand-right</v-icon>
            <v-icon v-else>mdi-arrow-collapse-left</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </template>

  </v-navigation-drawer>
</template>

<script>
export default {
  name: 'Sidebar',

  data: () => ({
    mini: true, // todo - pull from cookie?
  }),
  computed: {
    user() {
      return this.$store.state.auth.user.user;
    },
  },
};
</script>

/* eslint-disable camelcase */

import httpClient from './httpClient';

const END_POINT = '/farms';

const list = () => httpClient.get(END_POINT, { cache: false });

const search = (params, cache = true) => httpClient.get(`${END_POINT}/search`, { params, cache });

const get = (id) => httpClient.get(`${END_POINT}/${id}`);

const save = (id, data) => httpClient.patch(`${END_POINT}/${id}`, data);

// maybe more than one..
const create = (data) => httpClient.post(END_POINT, data);

export {
  list,
  search,
  get,
  save,
  create,
};
